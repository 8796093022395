import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import ServiceStationCardUtil,{IServiceStationCardDataObj} from './serviceStationCardUtil';
export default defineComponent({
    name:'ServiceStationCard',
    title:'服务站',
    modelType:'card',
    fullscreen: false,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IServiceStationCardDataObj=reactive<IServiceStationCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hsDetails: false,
                modelPath: utils.ToolsProviderApi.buildUrl('/serviceStation')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                name: [utils.UtilPub.commonValidRule('请输入服务站名称')],
                code: [utils.UtilPub.commonValidRule('请输入账号')]
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ServiceStationCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        const startHandler=()=>{
            dataObj.utilInst.changeStatus({id: dataObj.form.id,type:'start',operateType:'changeStatus'});
        }
        const stopHandler=()=>{
            dataObj.utilInst.changeStatus({id: dataObj.form.id,type:'stop',operateType:'changeStatus'});
        }
        const resetPwdHandler=()=>{
            dataObj.utilInst.resetPwd()
        }
        return{
            ...toRefs(dataObj),beforeOpen,beforeSaveHandler,startHandler,stopHandler,resetPwdHandler
         
        }
    }
});